import {
    $, addAction, INIT, applyFilters,
} from '@situation/setdesign.util';

/**
 * Handle click events for "scroll-to"
 * @param {Event} e - Click event
 */
const handleClick = (e) => {
    const $target = $(e.currentTarget.getAttribute('href'));
    if ($target.length) {
        e.preventDefault();
        // Scroll to the top of the target element minus the height of the header
        $('html, body').animate(
            { scrollTop: $target.offset().top - applyFilters('header-height') },
            500,
        );
    }
};

// Add click event listener to "scroll-to"
addAction(INIT, () => $('.scroll-to, a.scroll-to').on('click', handleClick));

import {
    $, addAction, doAction, INIT,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    const $audioContainer = $('.audio-ee');
    if (!$audioContainer.length) {
        return;
    }

    const $audioEl = $audioContainer.find('audio');
    $audioEl[0].volume = 0.7;
    const $target = $(`.${$audioContainer.data('target') || 'eelink'}`);
    $target.on('click mouseenter touchstart', () => {
        $audioEl[0].play();
        doAction(SetDesign.GTM_INTERACTION, { type: 'audio-playback', label: 'easter-egg' });
    });
});

import {
    $, _, addAction, INIT,
} from '@situation/setdesign.util';

let disable = false;

/**
 * Detects user's exit intent
 * @function detectExitIntent
 * @void
 */
const detectExitIntent = () => {
    if (sessionStorage.getItem('exit-shown')) {
        return;
    }

    /**
     * Triggers custom event on exit intent.
     * @function triggerCustomEvent
     * @void
     */
    const triggerCustomEvent = _.debounce(
        () => {
            if (sessionStorage.getItem('exit-shown') || disable) {
                return;
            }

            sessionStorage.setItem('exit-shown', 'true');

            if (window.hasOwnProperty('hellobar')) {
                window.hellobar.trigger.event('CustomExitIntent');
            }
        },
        500,
        { leading: false, trailing: true },
    );
    document.addEventListener('mouseout', (e) => {
        if (!e.toElement && !e.relatedTarget && (e.clientY < 5 || e.clientX < 5)) {
            triggerCustomEvent();
        }
    });

    document.addEventListener('visibilitychange', () => {
        if (document.hidden || document.visibilityState === 'hidden') {
            triggerCustomEvent();
        } else {
            disable = false;
        }
    });

    $(document).on('click', 'a[href*="telecharge"]', () => {
        disable = true;
    });
};

addAction(INIT, detectExitIntent);

import { $ } from '@situation/setdesign.util';

$(document).one('TicketCalendar.init', (e, TicketCalendar, $on) => {
    $on(TicketCalendar.VUE_RENDERED, () => {
        const $toggleButton = $('.tc-accordion__toggle');
        $toggleButton.each((_, elem) => {
            $(elem).prepend('<svg class="icon"><use xlink:href="#icon-down-arrow"></use></svg>');
        });
    });

    $on(TicketCalendar.FILTER_DEFAULTS, (defaults) => {
        defaults.update({
            // set to the $tc-theme-name sass variable
            theme: 'beautifulnoise-theme',
            'disable-default-analytics': true,
        });
    });
});
